<template>
    <v-form 
        ref="form"
        class="py-8 px-6" 
        style="background-color: #FFFFFF;"
        @submit.prevent="handleSubmit()" 
    >
        <slot name="form-header"></slot>
        <v-text-field 
            label="Cantidad" 
            required 
            type="number"
            v-model="form.cantidad"
            outlined
            light
            :error-messages="cantidadErrors"
            @change="$v.form.cantidad.$touch()"
            @blur="$v.form.cantidad.$touch()"
            style="width: 100%"
        />
        <v-textarea 
            v-model="form.comentario" 
            label="Comentario" 
            placeholder="Comentario" 
            outlined 
            light
            :error-messages="comentarioErrors"
            @change="$v.form.comentario.$touch()"
            @blur="$v.form.comentario.$touch()"
        />
        <div class="mt-4 d-flex" style="gap: 16px;">
            <v-btn class="flex-shrink-1 flex-grow-1" color="primary" type="submit">{{ ctaText }}</v-btn>
            <v-btn class="flex-shrink-1 flex-grow-1" color="secondary" @click.stop="handleCancel">{{ cancelText }}</v-btn>
        </div>
    </v-form>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, minValue, maxLength } from 'vuelidate/lib/validators'
import { Validator } from '@/utils/form-validation.js';

const initialFormState = {
    comentario: null,
    cantidad: null,
};

export default {
    name: 'CantidadForm',
    mixins: [validationMixin],
    validations: {
        form: {
            comentario: { required, maxLength: maxLength(300) },
            cantidad: { required, minValue: minValue(1) },
        },
    },
    props: {
        ctaText: { type: String, default: 'Continuar' },
        cancelText: { type: String, default: 'Cerrar' },
    },
    data: () => ({
        form: {...initialFormState},
        minDate: new Date(),
    }),
    computed: {
        comentarioErrors() {
            return new Validator(this.$v.form.comentario).detect().getResult();
        },
        cantidadErrors() {
            return new Validator(this.$v.form.cantidad).detect().getResult();
        },
    },
    emits: ['on-cancel', 'on-save'],
    methods: {
        clearForm() {
            this.$v.$reset();
            this.$refs.form.reset();
            this.form = initialFormState;
        },
        handleSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.$emit('on-save', {...this.form});
            this.clearForm();
        },
        handleCancel() {
            this.clearForm();
            this.$emit('on-cancel');
        },
    },
};
</script>