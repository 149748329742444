<template>
<v-form 
    ref="form"
    class="py-6 px-6" 
    style="background-color: #FFFFFF;"
    @submit.prevent="handleSubmit()" 
>
    <slot name="form-header"></slot>
    <v-textarea 
        v-model="form.comentario" 
        label="Comentario" 
        placeholder="Comentario" 
        outlined 
        :error-messages="comentarioErrors"
        @change="$v.form.comentario.$touch()"
        @blur="$v.form.comentario.$touch()"
    />
    <div class="mt-4 d-flex" style="gap: 16px;">
        <v-btn 
            class="flex-shrink-1 flex-grow-1" 
            color="secondary" 
            @click.stop="handleCancel"
        >
            {{ cancelText }}
        </v-btn>
        <v-btn 
            class="flex-shrink-1 flex-grow-1" 
            color="primary" 
            type="submit"
        >
            {{ ctaText }}
        </v-btn>
    </div>
</v-form>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { Validator } from '@/utils/form-validation.js';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
    name: 'ComentarioForm',
    mixins: [validationMixin],
    emits: ['on-cancel', 'on-save', ], 
    validations: {
        form: {
            comentario: { required, maxLength: maxLength(300), },
        },
    },
    props: {
        ctaText: { type: String, default: 'Rechazar', },
        cancelText: { type: String, default: 'Cerrar', },
    },
    data: () => ({
        form: {
            comentario: '',
        },
    }),
    computed: {
        comentarioErrors() {
            return new Validator(this.$v.form.comentario).detect().getResult();
        },
    },
    methods: {
        clearForm() {
            this.$v.$reset();
            this.$refs.form.reset();
        },
        handleSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.$emit('on-save', {...this.form});
            this.clearForm();
        },
        handleCancel() {
            this.clearForm();
            this.$emit('on-cancel');
        },
    },
};
</script>