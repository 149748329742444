<template>
    <v-form 
        ref="form"
        class="py-8 px-6" 
        style="background-color: #FFFFFF;"
        @submit.prevent="handleSubmit()" 
    >
        <slot name="form-header"></slot>
        
        <v-menu transition="scale-transition" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <v-text-field 
                        label="Fecha" 
                        placeholder="Fecha"
                        light 
                        outlined 
                        readonly
                        :error-messages="fecha_citaErrors"
                        :value="fecha_citaValue"
                        @change="$v.form.fecha_cita.$touch()"
                        @blur="$v.form.fecha_cita.$touch()"
                    />
                </div>
            </template>
            <div @click.stop>
                <vc-date-picker 
                    v-model="form.fecha_cita"
                    mode="date"
                    locale="es"    
                    :min-date="minDate"                    
                />
            </div>
        </v-menu>
        <v-menu transition="scale-transition" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <v-text-field 
                        label="Hora" 
                        placeholder="Hora"
                        light 
                        outlined 
                        readonly
                        :error-messages="hora_citaErrors"
                        :value="hora_citaValue"
                        @change="$v.form.hora_cita.$touch()"
                        @blur="$v.form.hora_cita.$touch()"
                    />
                </div>
            </template>
            <div @click.stop>
                <vc-date-picker 
                    hide-time-header
                    v-model="form.hora_cita"
                    mode="time"
                    locale="es"    
                    class="fecha-form-time-picker"
                />
            </div>
        </v-menu>
        <v-textarea 
            v-model="form.comentario" 
            label="Comentario" 
            placeholder="Comentario" 
            outlined 
            light
            :error-messages="comentarioErrors"
            @change="$v.form.comentario.$touch()"
            @blur="$v.form.comentario.$touch()"
        />
        <div class="mt-4 d-flex" style="gap: 16px;">
            <v-btn class="flex-shrink-1 flex-grow-1" color="secondary" @click.stop="handleCancel">{{ cancelText }}</v-btn>
            <v-btn class="flex-shrink-1 flex-grow-1" color="primary" type="submit">{{ ctaText }}</v-btn>
        </div>
    </v-form>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators'
import { Validator } from '@/utils/form-validation.js';
import moment from 'moment';

const initialFormState = {
    fecha_cita: null,
    hora_cita: null,
    comentario: null,
};

export default {
    name: 'FechaForm',
    mixins: [validationMixin],
    validations: {
        form: {
            fecha_cita: { required },
            hora_cita: { required },
            comentario: { maxLength: maxLength(300) },
        },
    },
    props: {
        ctaText: { type: String, default: 'Continuar' },
        cancelText: { type: String, default: 'Cerrar' },
    },
    data: () => ({
        form: {...initialFormState},
        minDate: new Date(),
    }),
    computed: {
        fecha_citaErrors() {
            return new Validator(this.$v.form.fecha_cita).detect().getResult();
        },
        hora_citaErrors() {
            return new Validator(this.$v.form.hora_cita).detect().getResult();
        },
        fecha_citaValue() {
            if (!this.form.fecha_cita) return;

            return moment(this.form.fecha_cita).format("DD-MM-YYYY")
        },
        hora_citaValue() {
            if (!this.form.hora_cita) return;

            return moment(this.form.hora_cita).format("HH:mm")
        },
        comentarioErrors() {
            return new Validator(this.$v.form.comentario).detect().getResult();
        },
    },
    emits: ['on-cancel', 'on-save'],
    methods: {
        clearForm() {
            this.$v.$reset();
            this.$refs.form.reset();
            this.form = initialFormState;
        },
        handleSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            const fecha = this.form.fecha_cita;
            fecha.setHours(this.form.hora_cita.getHours(), this.form.hora_cita.getMinutes(), 0)
            const data = { fecha: fecha.toISOString(), comentario: this.form.comentario };

            this.$emit('on-save', data);
            this.clearForm();
        },
        handleCancel() {
            this.clearForm();
            this.$emit('on-cancel');
        },
    },
};
</script>
<style>
    .fecha-form-time-picker .vc-date {
        display: none !important;
    }
</style>